import {
  AagjeService,
  Activation,
  AuthenticationService,
  CalendarEvent_calendar_event_create,
  CalendarEvent_calendar_event_update,
  CalendarEventAdministrationService,
  CalendarEventService,
  CategoryService,
  Company_company_update,
  CompanyAdministrationService,
  CompanyService,
  Offer_offer_create,
  Offer_offer_update,
  OfferAdministrationService,
  OfferService,
  OpenAPI,
  PasswordReset,
  PasswordResetRequest,
  RegionService,
  Registration,
  SessionLogin,
  UserAdministrationService,
  UserService,
  AdministrationService,
  User_user_admin_list,
  Registration_registration_admin_list,
  Company_company_admin_list_region_embed,
  Offer_offer_admin_list,
  CalendarEvent_calendar_events_admin_list,
  Offer_offer_list,
  Category_category_list,
} from 'generated/api';
import { useQuery } from 'react-query';
import { getPaginationDataFromResponseHeaders } from './pagination';

OpenAPI.BASE = process.env.NEXT_PUBLIC_API_URL || '';
OpenAPI.WITH_CREDENTIALS = true;

export const login = (requestBody: SessionLogin) => AuthenticationService.sessionLogin({ requestBody });
export const logout = AuthenticationService.sessionLogout;

export const activateUser = (requestBody: Activation) => UserService.registerActivationCollection({ requestBody });
export const getUser = UserService.meUserItem;
export const register = (requestBody: Registration) => UserService.registerRegistrationCollection({ requestBody });
export const requestPasswordReset = (requestBody: PasswordResetRequest) =>
  UserService.passwordresetRequestPasswordResetRequestCollection({ requestBody });
export const resetPassword = (requestBody: PasswordReset) =>
  UserService.passwordresetResetPasswordResetCollection({ requestBody });

export const useSearchCompaniesAagje = ({ region, query }: Parameters<typeof AagjeService.aagjeSearchCompanies>[0]) =>
  useQuery(['useSearchCompaniesAagje', { region, query }], () => AagjeService.aagjeSearchCompanies({ region, query }), {
    enabled: !!region && !!query,
  });

export const useRegions = ({ page, itemsPerPage = 10_000 }: Parameters<typeof RegionService.getRegionCollection>[0]) =>
  useQuery(['useRegions', { page, itemsPerPage }], () => RegionService.getRegionCollection({ page, itemsPerPage }));

/* Companies */

export const useCompanies = ({
  enabled,
  page,
  itemsPerPage = 10_000,
}: {
  enabled?: boolean;
  page?: number;
  itemsPerPage?: number;
}) =>
  useQuery(
    ['useCompanies', { page, itemsPerPage, enabled }],
    () => CompanyService.getCompanyCollection({ page, itemsPerPage }),
    { enabled }
  );

export const useCompany = ({ uuid }: { uuid?: string }) =>
  useQuery(['useCompany', { uuid }], () => (uuid ? CompanyService.getCompanyItem({ uuid }) : undefined), {
    enabled: !!uuid,
  });

export const saveCompanyConcept = (uuid: string, requestBody: Company_company_update) =>
  CompanyService.putCompanyItem({ uuid, requestBody });

export const saveCompany = (uuid: string, requestBody: Company_company_update) =>
  CompanyService.confirmCompanyItem({ uuid, requestBody });

export const useAdminCompaniesWithPagination = ({
  enabled,
  page,
  itemsPerPage = 30,
  name,
  region,
  status,
}: {
  enabled?: boolean;
  page?: number;
  itemsPerPage?: number;
  name?: string;
  region?: string;
  status?: string;
}) =>
  useQuery(
    ['useAdminCompaniesWithPagination', { page, itemsPerPage, name, region, status }],
    async () => {
      // Cannot use the generated API because we need to access the headers for pagination.
      const response = await fetch(
        `${OpenAPI.BASE}/api/admin/companies${getQueryString({ page, itemsPerPage, name, 'region.code': region, status })}`,
        {
          headers: { accept: 'application/json' },
          credentials: 'include',
        }
      );
      const data: Company_company_admin_list_region_embed[] = await response.json();
      return {
        data,
        pagination: getPaginationDataFromResponseHeaders(response),
      };
    },
    { enabled }
  );

export const useAdminCompany = ({ uuid }: { uuid?: string }) =>
  useQuery(
    ['useAdminCompany', { uuid }],
    () => (uuid ? CompanyAdministrationService.adminGetCompanyItem({ uuid }) : undefined),
    {
      enabled: !!uuid,
    }
  );

export const adminAcceptCompany = ({ uuid }: { uuid: string }) =>
  CompanyAdministrationService.adminAcceptCompanyItem({ uuid, requestBody: {} });

export const adminPutCompany = (uuid: string, requestBody: Company_company_update) =>
  CompanyAdministrationService.adminPutCompanyItem({ uuid, requestBody });

/* Offers */

export const useOffersWithPagination = ({
  enabled,
  page,
  itemsPerPage = 30,
}: {
  enabled?: boolean;
  page?: number;
  itemsPerPage?: number;
}) =>
  useQuery(
    ['useOffersWithPagination', { page, itemsPerPage }],
    async () => {
      // Cannot use the generated API because we need to access the headers for pagination.
      const response = await fetch(`${OpenAPI.BASE}/api/offers${getQueryString({ page, itemsPerPage })}`, {
        headers: { accept: 'application/json' },
        credentials: 'include',
      });
      const data: Offer_offer_list[] = await response.json();
      return {
        data,
        pagination: getPaginationDataFromResponseHeaders(response),
      };
    },
    {
      enabled,
    }
  );

export const useOffer = ({ uuid }: { uuid?: string }) =>
  useQuery(['useOffer', { uuid }], () => (uuid ? OfferService.getOfferItem({ uuid }) : undefined), {
    enabled: !!uuid,
  });

export const createOfferConcept = (requestBody: Offer_offer_create) =>
  OfferService.postOfferCollection({ requestBody });

export const createOffer = (requestBody: Offer_offer_create) => OfferService.confirmOfferCollection({ requestBody });

export const saveOfferConcept = (uuid: string, requestBody: Offer_offer_update) =>
  OfferService.putOfferItem({ uuid, requestBody });

export const saveOffer = (uuid: string, requestBody: Offer_offer_update) =>
  OfferService.confirmOfferItem({ uuid, requestBody });

export const retractOffer = (uuid: string) => OfferService.retractOfferItem({ uuid, requestBody: {} }); // Note, we send an empty body cause API Platform requires that on PUT

export const useAdminOffersWithPagination = ({
  enabled,
  page,
  itemsPerPage = 30,
}: {
  enabled?: boolean;
  page?: number;
  itemsPerPage?: number;
}) =>
  useQuery(
    ['useAdminOffersWithPagination', { page, itemsPerPage }],
    async () => {
      // Cannot use the generated API because we need to access the headers for pagination.
      const response = await fetch(`${OpenAPI.BASE}/api/admin/offers${getQueryString({ page, itemsPerPage })}`, {
        headers: { accept: 'application/json' },
        credentials: 'include',
      });
      const data: Offer_offer_admin_list[] = await response.json();
      return {
        data,
        pagination: getPaginationDataFromResponseHeaders(response),
      };
    },
    {
      enabled,
    }
  );

export const useAdminOffer = ({ uuid }: { uuid?: string }) =>
  useQuery(
    ['useAdminOffer', { uuid }],
    () => (uuid ? OfferAdministrationService.adminGetOfferItem({ uuid }) : undefined),
    {
      enabled: !!uuid,
    }
  );

export const adminAcceptOffer = ({ uuid }: { uuid: string }) =>
  OfferAdministrationService.adminAcceptOfferItem({ uuid, requestBody: {} });

export const adminPutOffer = (uuid: string, requestBody: Offer_offer_update) =>
  OfferAdministrationService.adminPutOfferItem({ uuid, requestBody });

/* Categories */

export const useCategories = ({
  enabled,
  page,
  itemsPerPage = 10_000,
}: {
  enabled?: boolean;
  page?: number;
  itemsPerPage?: number;
}) =>
  useQuery<Category_category_list[]>(
    ['useCategories', { page, itemsPerPage }],
    () => CategoryService.getCategoryCollection({ page, itemsPerPage }),
    {
      enabled,
    }
  );

/* Registrations */
export const useAdminRegistrations = ({ page, itemsPerPage = 30 }: { page?: number; itemsPerPage?: number }) =>
  useQuery(['useAdminRegistrations', { page, itemsPerPage }], () =>
    UserAdministrationService.adminGetRegistrationCollection({ page, itemsPerPage })
  );

export const useAdminRegistrationsWithPagination = ({
  page,
  itemsPerPage = 20,
}: {
  page?: number;
  itemsPerPage?: number;
}) =>
  useQuery(['useAdminRegistrationsWithPagination', { page, itemsPerPage }], async () => {
    // Cannot use the generated API because we need to access the headers for pagination.
    const response = await fetch(`${OpenAPI.BASE}/api/admin/registrations${getQueryString({ page, itemsPerPage })}`, {
      headers: { accept: 'application/json' },
      credentials: 'include',
    });
    const data: Registration_registration_admin_list[] = await response.json();
    return {
      data,
      pagination: getPaginationDataFromResponseHeaders(response),
    };
  });

export const useAdminRegistration = ({ code }: { code?: string }) =>
  useQuery(
    ['useAdminRegistration', { code }],
    () => (code ? UserAdministrationService.adminGetRegistrationItem({ code }) : undefined),
    { enabled: !!code }
  );

export const acceptAdminRegistration = ({ code }: { code: string }) =>
  UserAdministrationService.adminAcceptRegistrationItem({ code, requestBody: {} });

export const rejectAdminRegistration = ({ code }: { code: string }) =>
  UserAdministrationService.adminRejectRegistrationItem({ code });

export const useAdminUsersWithPagination = ({
  page,
  itemsPerPage = 20,
  email,
  name,
}: {
  page?: number;
  itemsPerPage?: number;
  email?: string;
  name?: string;
}) =>
  useQuery(['useAdminUsersWithPagination', { page, itemsPerPage, email, name }], async () => {
    // Cannot use the generated API because we need to access the headers for pagination.
    const response = await fetch(
      `${OpenAPI.BASE}/api/admin/users${getQueryString({ page, itemsPerPage, email, name })}`,
      {
        headers: { accept: 'application/json' },
        credentials: 'include',
      }
    );
    const data: User_user_admin_list[] = await response.json();
    return {
      data,
      pagination: getPaginationDataFromResponseHeaders(response),
    };
  });

export const useAdminUser = ({ uuid }: { uuid?: string }) =>
  useQuery(
    ['useAdminUser', { uuid }],
    () => (uuid ? UserAdministrationService.adminGetUserItem({ uuid }) : undefined),
    { enabled: !!uuid }
  );

/* CalendarEvents */

export const useCalendarEventsWithPagination = ({
  enabled,
  page,
  itemsPerPage = 30,
}: {
  enabled?: boolean;
  page?: number;
  itemsPerPage?: number;
}) =>
  useQuery(
    ['useCalendarEvents', { page, itemsPerPage }],
    async () => {
      const response = await fetch(`${OpenAPI.BASE}/api/calendar_events${getQueryString({ page, itemsPerPage })}`, {
        headers: { accept: 'application/json' },
        credentials: 'include',
      });
      const data: User_user_admin_list[] = await response.json();
      return {
        data,
        pagination: getPaginationDataFromResponseHeaders(response),
      };
    },
    {
      enabled,
    }
  );

export const useCalendarEvent = ({ uuid }: { uuid?: string }) =>
  useQuery(
    ['useCalendarEvent', { uuid }],
    () => (uuid ? CalendarEventService.getCalendarEventItem({ uuid }) : undefined),
    {
      enabled: !!uuid,
    }
  );

export const createCalendarEventConcept = (requestBody: CalendarEvent_calendar_event_create) =>
  CalendarEventService.postCalendarEventCollection({ requestBody });

export const createCalendarEvent = (requestBody: CalendarEvent_calendar_event_create) =>
  CalendarEventService.confirmCalendarEventCollection({ requestBody });

export const saveCalendarEventConcept = (uuid: string, requestBody: CalendarEvent_calendar_event_update) =>
  CalendarEventService.putCalendarEventItem({ uuid, requestBody });

export const saveCalendarEvent = (uuid: string, requestBody: CalendarEvent_calendar_event_update) =>
  CalendarEventService.confirmCalendarEventItem({ uuid, requestBody });

export const useAdminCalendarEventsWithPagination = ({
  enabled,
  page,
  itemsPerPage = 30,
}: {
  enabled?: boolean;
  page?: number;
  itemsPerPage?: number;
}) =>
  useQuery(
    ['useAdminCalendarEventsWithPagination', { page, itemsPerPage }],
    async () => {
      // Cannot use the generated API because we need to access the headers for pagination.
      const response = await fetch(
        `${OpenAPI.BASE}/api/admin/calendar_events${getQueryString({ page, itemsPerPage })}`,
        {
          headers: { accept: 'application/json' },
          credentials: 'include',
        }
      );
      const data: CalendarEvent_calendar_events_admin_list[] = await response.json();
      return {
        data,
        pagination: getPaginationDataFromResponseHeaders(response),
      };
    },
    {
      enabled,
    }
  );

export const useAdminCalendarEvent = ({ uuid }: { uuid?: string }) =>
  useQuery(
    ['useAdminCalendarEvent', { uuid }],
    () => (uuid ? CalendarEventAdministrationService.adminGetCalendarEventItem({ uuid }) : undefined),
    {
      enabled: !!uuid,
    }
  );

export const adminAcceptCalendarEvent = ({ uuid }: { uuid: string }) =>
  CalendarEventAdministrationService.adminAcceptCalendarEventItem({ uuid, requestBody: {} });

export const adminPutCalendarEvent = (uuid: string, requestBody: CalendarEvent_calendar_event_update) =>
  CalendarEventAdministrationService.adminPutCalendarEventItem({ uuid, requestBody });

export const useAdminStatusSummary = () =>
  useQuery('useAdminStatusSummary', () => AdministrationService.adminStatusSummary({}));

export const setAdminNotesForUser = (userUuid: string, adminNotes: string) =>
  UserAdministrationService.adminPutUserItem({ uuid: userUuid, requestBody: { adminNotes } as any });

export const adminDeleteUser = (userUuid: string) => UserAdministrationService.adminDeleteUserItem({ uuid: userUuid });

const getQueryString = (parameters: Record<string, any>): string => {
  const qs: string[] = [];

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const process = (key: string, value: any) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        for (const v of value) {
          process(key, v);
        }
      } else if (typeof value === 'object') {
        for (const [k, v] of Object.entries(value)) {
          process(`${key}[${k}]`, v);
        }
      } else {
        append(key, value);
      }
    }
  };

  for (const [key, value] of Object.entries(parameters)) {
    process(key, value);
  }

  if (qs.length > 0) {
    return `?${qs.join('&')}`;
  }

  return '';
};
