/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Company_company_list = {
    readonly uuid?: string;
    status?: Company_company_list.status;
    upsell?: Company_company_list.upsell;
    name?: string;
    region?: string;
    location?: string;
    readonly primaryImage?: string | null;
    readonly profileEmpty?: boolean;
};

export namespace Company_company_list {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_UPSELL = 'call_upsell',
        CALL_ADVICE = 'call_advice',
    }


}

