/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionButton_jsonld_company_view } from './ActionButton_jsonld_company_view';
import type { CompanyCategory_jsonld_company_view } from './CompanyCategory_jsonld_company_view';
import type { KnownFor_jsonld_company_view } from './KnownFor_jsonld_company_view';
import type { OpeningTimes_jsonld_company_view } from './OpeningTimes_jsonld_company_view';

export type Company_jsonld_company_view = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly uuid?: string;
    status?: Company_jsonld_company_view.status;
    address?: string;
    zipCode?: string;
    images?: Array<string>;
    description?: string;
    knownFor?: KnownFor_jsonld_company_view;
    oneLiner?: string;
    openingTimes?: OpeningTimes_jsonld_company_view;
    actionButtons?: Array<ActionButton_jsonld_company_view>;
    website?: string;
    facebook?: string;
    instagram?: string;
    linkedIn?: string;
    pinterest?: string;
    twitter?: string;
    youtube?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;
    upsell?: Company_jsonld_company_view.upsell;
    categories?: Array<CompanyCategory_jsonld_company_view>;
    name?: string;
    region?: string;
    location?: string;
    readonly primaryImage?: string | null;
    readonly profileEmpty?: boolean;
};

export namespace Company_jsonld_company_view {

    export enum status {
        EMPTY = 'empty',
        CONCEPT = 'concept',
        CONFIRMED = 'confirmed',
        ACCEPTED = 'accepted',
        SUBMITTED = 'submitted',
    }

    export enum upsell {
        BASIC = 'basic',
        PREMIUM = 'premium',
        PREMIUM_PLUS = 'premium_plus',
        CALL_UPSELL = 'call_upsell',
        CALL_ADVICE = 'call_advice',
    }


}

